import {
  ROUTES_EMPLOYEES, ROUTES_CUSTOMERS, ROUTES_SUPERVISORS, ROUTES_PARTNERS, ROUTES_EMPLOYEE_OVERVIEWS,
} from '@/app/data/route_constants';
import { PROFILE_TYPES } from '@/app/data/model_constants';
import { FT } from '@/app/data/flipper_constants';

export const NAVBAR_ITEMS_CUSTOMER = [
  {
    data_test: 'customer-navbar-dashboard',
    icon: 'grid-horizontal',
    name: ROUTES_CUSTOMERS.DASHBOARD,
    prefix: ROUTES_CUSTOMERS.DASHBOARD,
    title: 'navbar.customer.dashboard',
  },
  {
    data_test: 'customer-navbar-tickets',
    icon: 'comments',
    name: ROUTES_CUSTOMERS.TICKETS,
    prefix: ROUTES_CUSTOMERS.TICKETS,
    title: 'navbar.customer.tickets',
  },
  {
    data_test: 'customer-navbar-documents',
    icon: ['far', 'file-lines'],
    name: ROUTES_CUSTOMERS.DOCUMENTS_ALL,
    prefix: ROUTES_CUSTOMERS.DOCUMENTS,
    title: 'navbar.customer.documents',
  },
  {
    data_test: 'customer-navbar-decisions',
    flipper: FT.DECISIONS,
    icon: ['far', 'house-circle-check'],
    name: ROUTES_CUSTOMERS.DECISIONS,
    prefix: 'customersDecision',
    title: 'navbar.customer.decisions',
  },
  {
    data_test: 'customer-navbar-installments',
    icon: ['far', 'receipt'],
    name: ROUTES_CUSTOMERS.INSTALLMENT_TERMS,
    prefix: 'customersInstallmentTerms',
    title: 'navbar.customer.installments',
    flipper: FT.FINANCIALS,
  },
  {
    data_test: 'customer-navbar-aftercare',
    icon: ['far', 'house-chimney-heart'],
    name: ROUTES_CUSTOMERS.ISSUES,
    prefix: 'customersIssues',
    title: 'navbar.customer.after_care',
    flipper: FT.AFTERCARE,
  },
  {
    data_test: 'customer-navbar-inspirationlists',
    icon: 'lightbulb',
    name: ROUTES_CUSTOMERS.INSPIRATION_LISTS,
    prefix: 'customersInspirationLists',
    title: 'navbar.customer.inspirations',
  },
  {
    data_test: 'customer-navbar-questionlists',
    icon: 'circle-question',
    name: ROUTES_CUSTOMERS.QUESTION_LISTS,
    prefix: ROUTES_CUSTOMERS.QUESTION_LISTS,
    title: 'navbar.customer.question_lists',
  },
  {
    data_test: 'customer-navbar-projectinformation',
    icon: 'info',
    name: ROUTES_CUSTOMERS.PROJECT_INFORMATION,
    prefix: ROUTES_CUSTOMERS.PROJECT_INFORMATION,
    title: 'navbar.customer.projectinfo',
  },
];

export const NAVBAR_ITEMS_EMPLOYEES_PROJECT = [
  {
    data_test: 'project-navbar-information',
    icon: 'info',
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
    prefix: 'employeesProjectInformation',
    title: 'navbar.employee.project.info',
    hidden_skip_customer: true,
  },
  {
    data_test: 'project-navbar-milestones',
    icon: 'timeline',
    name: ROUTES_EMPLOYEES.PROJECT_MILESTONES,
    prefix: ROUTES_EMPLOYEES.PROJECT_MILESTONES,
    title: 'navbar.employee.project.milestones',
    flipper: FT.MILESTONES
  },
  {
    data_test: 'project-navbar-sales',
    icon: 'handshake',
    name: ROUTES_EMPLOYEES.PROJECT_SALES_UNITS,
    prefix: 'employeesProjectSales',
    title: 'navbar.employee.project.sales',
    flipper: 'sales',
    hidden_skip_customer: true,
  },
  {
    data_test: 'project-navbar-todolists',
    icon: ['far', 'square-check'],
    name: ROUTES_EMPLOYEES.PROJECT_TODO_LISTS,
    prefix: ROUTES_EMPLOYEES.PROJECT_TODO_LISTS,
    title: 'navbar.employee.project.todos',
    hidden_skip_customer: false,
  },
  {
    data_test: 'project-navbar-documents',
    icon: ['far', 'file-lines'],
    name: ROUTES_EMPLOYEES.PROJECT_DOCUMENTS,
    prefix: ROUTES_EMPLOYEES.PROJECT_DOCUMENTS,
    title: 'navbar.employee.project.documents',
    hidden_skip_customer: false,
  },
  {
    data_test: 'project-navbar-tickets',
    icon: ['far', 'comment'],
    name: ROUTES_EMPLOYEES.PROJECT_TICKETS,
    prefix: ROUTES_EMPLOYEES.PROJECT_TICKETS,
    title: 'navbar.employee.tickets',
    hidden_skip_customer: true,
  },
  {
    data_test: 'project-navbar-decisions',
    flipper: FT.DECISIONS,
    icon: ['far', 'house-circle-check'],
    name: ROUTES_EMPLOYEES.PROJECT_DECISION_TYPES,
    prefix: 'employeesProjectDecision',
    title: 'navbar.employee.project.decisions',
    hidden_skip_customer: false,
  },
  {
    data_test: 'project-navbar-installments',
    icon: ['far', 'receipt'],
    name: ROUTES_EMPLOYEES.PROJECT_INSTALLMENT_GROUPS,
    prefix: 'employeesProjectInstallment',
    title: 'navbar.employee.project.installments',
    flipper: FT.FINANCIALS,
    hidden_skip_customer: true,
  },
  {
    data_test: 'project-navbar-aftercare',
    icon: ['far', 'house-chimney-heart'],
    name: ROUTES_EMPLOYEES.PROJECT_ISSUE_LIST_TYPES,
    prefix: 'employeesProjectIssueList',
    title: 'navbar.employee.project.after_care',
    flipper: FT.AFTERCARE,
    hidden_skip_customer: true,
  },
  {
    data_test: 'project-navbar-announcements',
    icon: ['far', 'newspaper'],
    name: ROUTES_EMPLOYEES.PROJECT_ANNOUNCEMENTS,
    prefix: ROUTES_EMPLOYEES.PROJECT_ANNOUNCEMENTS,
    title: 'navbar.employee.project.news',
    hidden_skip_customer: false,
  },
  {
    data_test: 'project-navbar-surveys',
    icon: ['far', 'clipboard-list'],
    name: ROUTES_EMPLOYEES.PROJECT_SURVEY_TYPES,
    prefix: 'employeesProjectSurvey',
    title: 'navbar.employee.project.surveys',
    hidden_skip_customer: false
  },
  {
    data_test: 'project-navbar-questionlists',
    icon: ['far', 'circle-question'],
    name: ROUTES_EMPLOYEES.PROJECT_QUESTION_LISTS,
    prefix: ROUTES_EMPLOYEES.PROJECT_QUESTION_LISTS,
    title: 'navbar.employee.project.question_lists',
    hidden_skip_customer: false,
  },
];

export const NAVBAR_ITEMS_EMPLOYEES_SECONDARY_NAVBAR = [
  {
    data_test: 'project-navbar-information',
    icon: 'info',
    name: ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION,
    prefix: 'employeesProjectInformation',
    title: 'navbar.employee.project.info',
    hidden_skip_customer: true,
  },
  {
    data_test: 'project-navbar-milestones',
    icon: 'timeline',
    name: ROUTES_EMPLOYEES.PROJECT_MILESTONES,
    prefix: ROUTES_EMPLOYEES.PROJECT_MILESTONES,
    title: 'navbar.employee.project.milestones',
    flipper: FT.MILESTONES
  },
  {
    data_test: 'project-navbar-sales',
    icon: 'handshake',
    name: ROUTES_EMPLOYEES.PROJECT_SALES_UNITS,
    prefix: 'employeesProjectSales',
    title: 'navbar.employee.project.sales',
    flipper: FT.SALES,
    hidden_skip_customer: true,
  },
  {
    data_test: 'project-navbar-todolists',
    icon: ['far', 'square-check'],
    name: ROUTES_EMPLOYEES.PROJECT_TODO_LISTS,
    prefix: ROUTES_EMPLOYEES.PROJECT_TODO_LISTS,
    title: 'navbar.employee.project.todos',
    hidden_skip_customer: false,
  },
  {
    data_test: 'project-navbar-documents',
    icon: ['far', 'file-lines'],
    name: ROUTES_EMPLOYEES.PROJECT_DOCUMENTS,
    prefix: ROUTES_EMPLOYEES.PROJECT_DOCUMENTS,
    title: 'navbar.employee.project.documents',
    hidden_skip_customer: false,
  },
  {
    data_test: 'project-navbar-tickets',
    icon: ['far', 'comment'],
    name: ROUTES_EMPLOYEES.PROJECT_TICKETS,
    prefix: ROUTES_EMPLOYEES.PROJECT_TICKETS,
    title: 'navbar.employee.tickets',
    hidden_skip_customer: true,
  },
  {
    data_test: 'project-navbar-decisions',
    flipper: FT.DECISIONS,
    icon: ['far', 'house-circle-check'],
    name: ROUTES_EMPLOYEES.PROJECT_DECISION_TYPES,
    prefix: 'employeesProjectDecision',
    title: 'navbar.employee.project.decisions',
    hidden_skip_customer: false,
  },
  {
    data_test: 'project-navbar-installments',
    icon: ['far', 'receipt'],
    name: ROUTES_EMPLOYEES.PROJECT_INSTALLMENT_GROUPS,
    prefix: 'employeesProjectInstallment',
    title: 'navbar.employee.project.installments',
    flipper: FT.FINANCIALS,
    hidden_skip_customer: true,
  },
  {
    data_test: 'project-navbar-aftercare',
    icon: ['far', 'house-chimney-heart'],
    name: ROUTES_EMPLOYEES.PROJECT_ISSUE_LIST_TYPES,
    prefix: 'employeesProjectIssueList',
    title: 'navbar.employee.project.after_care',
    flipper: FT.AFTERCARE,
    hidden_skip_customer: true,
  },
  {
    data_test: 'project-navbar-announcements',
    icon: ['far', 'newspaper'],
    name: ROUTES_EMPLOYEES.PROJECT_ANNOUNCEMENTS,
    prefix: ROUTES_EMPLOYEES.PROJECT_ANNOUNCEMENTS,
    title: 'navbar.employee.project.news',
    hidden_skip_customer: false,
  },
  {
    data_test: 'project-navbar-surveys',
    icon: ['far', 'clipboard-list'],
    name: ROUTES_EMPLOYEES.PROJECT_SURVEY_TYPES,
    prefix: 'employeesProjectSurvey',
    title: 'navbar.employee.project.surveys',
    hidden_skip_customer: false,
  },
  {
    data_test: 'project-navbar-questionlists',
    icon: ['far', 'circle-question'],
    name: ROUTES_EMPLOYEES.PROJECT_QUESTION_LISTS,
    prefix: ROUTES_EMPLOYEES.PROJECT_QUESTION_LISTS,
    title: 'navbar.employee.project.question_lists',
    hidden_skip_customer: false,
  },
];

export const NAVBAR_ITEMS_EMPLOYEES_OVERVIEW_NAVBAR = [
  {
    data_test: 'project-navbar-tasks-overview',
    icon: ['far', 'square-check'],
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_TASKS,
    prefix: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_TASKS,
    title: 'navbar.employee.project.todos',
  },
  {
    data_test: 'project-navbar-milestones-overview',
    icon: ['far', 'timeline'],
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_MILESTONES,
    prefix: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_MILESTONES,
    title: 'navbar.employee.project.milestones',
  },
  {
    data_test: 'project-navbar-documents-overview',
    icon: ['far', 'file-lines'],
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_DOCUMENTS,
    prefix: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_DOCUMENTS,
    title: 'navbar.employee.project.documents',
  },
  {
    data_test: 'project-navbar-conversations-overview',
    icon: ['far', 'comment'],
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_CONVERSATIONS,
    prefix: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_CONVERSATIONS,
    title: 'navbar.employee.tickets',
  },
  {
    data_test: 'project-navbar-decisions-overview',
    flipper: FT.DECISIONS,
    icon: ['far', 'house-circle-check'],
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_DECISIONS,
    prefix: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_DECISIONS,
    title: 'navbar.employee.project.decisions',
  },
  {
    data_test: 'project-navbar-financials-overview',
    flipper: FT.FINANCIALS,
    icon: ['far', 'receipt'],
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_FINANCIALS,
    prefix: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_FINANCIALS,
    title: 'navbar.employee.project.installments',
  },
  {
    data_test: 'project-navbar-aftercare-overview',
    flipper: FT.AFTERCARE,
    icon: ['far', 'house-chimney-heart'],
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_AFTERCARE,
    prefix: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_AFTERCARE,
    title: 'navbar.employee.project.after_care',
  },
  {
    data_test: 'project-navbar-news-overview',
    icon: ['far', 'newspaper'],
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_NEWS,
    prefix: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_NEWS,
    title: 'navbar.employee.project.news',
  },
  {
    data_test: 'project-navbar-surveys-overview',
    icon: ['far', 'clipboard-list'],
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_SURVEYS,
    prefix: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_SURVEYS,
    title: 'navbar.employee.project.surveys',
  },
  {
    data_test: 'project-navbar-faqs-overview',
    icon: ['far', 'circle-question'],
    name: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_FAQS,
    prefix: ROUTES_EMPLOYEE_OVERVIEWS.OVERVIEW_FAQS,
    title: 'navbar.employee.project.question_lists',
  },
];

export const NAVBAR_TABLET_ITEMS_EMPLOYEES_PROJECT = [
  {
    data_test: 'project-navbar-allprojects',
    icon: ['fas', 'arrow-left'],
    name: ROUTES_EMPLOYEES.CLIENT_PROJECTS,
    title: 'project.labels.projects',
  },
  ...NAVBAR_ITEMS_EMPLOYEES_PROJECT,
];

export const NAVBAR_ITEMS_EMPLOYEES_CLIENT = [
  {
    data_test: 'employee-navbar-dashboard',
    icon: 'grid-horizontal',
    name: ROUTES_EMPLOYEES.CLIENT_DASHBOARD,
    prefix: ROUTES_EMPLOYEES.CLIENT_DASHBOARD,
    title: 'navbar.employee.dashboard',
  },
  {
    data_test: 'employee-navbar-projects',
    icon: 'house',
    name: ROUTES_EMPLOYEES.CLIENT_PROJECTS,
    prefix: ROUTES_EMPLOYEES.CLIENT_PROJECTS,
    title: 'navbar.employee.projects',
  },
  {
    data_test: 'employee-navbar-tickets',
    icon: 'comments',
    name: ROUTES_EMPLOYEES.CLIENT_TICKETS,
    prefix: ROUTES_EMPLOYEES.CLIENT_TICKETS,
    title: 'navbar.employee.tickets',
  },
  {
    data_test: 'employee-navbar-contacts',
    icon: 'users',
    name: ROUTES_EMPLOYEES.CLIENT_CONTACTS_TYPE,
    prefix: ROUTES_EMPLOYEES.CLIENT_CONTACTS_TYPE,
    route_params: { profile_type: PROFILE_TYPES.CUSTOMER },
    title: 'navbar.employee.contacts',
  },
];

export const NAVBAR_ITEMS_SUPERVISORS = [
  {
    icon: 'grid-horizontal',
    prefix: ROUTES_SUPERVISORS.DASHBOARD,
    name: ROUTES_SUPERVISORS.DASHBOARD,
    title: 'navbar.supervisor.dashboard',
  },
  {
    icon: 'users',
    prefix: ROUTES_SUPERVISORS.CLIENT,
    name: ROUTES_SUPERVISORS.CLIENTS,
    title: 'navbar.supervisor.clients',
  },
  {
    icon: 'user',
    prefix: ROUTES_SUPERVISORS.USER,
    name: ROUTES_SUPERVISORS.USERS_SEARCH,
    title: 'navbar.supervisor.users',
  },
  {
    icon: 'code-branch',
    prefix: ROUTES_SUPERVISORS.AUDIT_LOG_DATA,
    name: ROUTES_SUPERVISORS.AUDIT_LOG_DATAS_SEARCH,
    title: 'navbar.supervisor.audit',
  },
  {
    icon: 'chart-bar',
    prefix: ROUTES_SUPERVISORS.METRICS,
    name: ROUTES_SUPERVISORS.METRICS,
    title: 'navbar.supervisor.metrics',
  },
  {
    icon: 'inbox',
    prefix: ROUTES_SUPERVISORS.EMAILS,
    name: ROUTES_SUPERVISORS.EMAILS,
    title: 'navbar.supervisor.emails',
  },
];

export const NAVBAR_ITEMS_PARTNERS_CLIENT = [
  {
    name: ROUTES_PARTNERS.CLIENT_PROJECTS,
    prefix: ROUTES_PARTNERS.CLIENT_PROJECTS,
    icon: 'house',
    title: 'navbar.partner.projects',
    data_test: 'partner-navbar-projects',
  },
];

export const NAVBAR_ITEMS_PARTNERS_PROJECT_ACTIVE_ROLE_NEEDED = [
  ROUTES_PARTNERS.PROJECT_DECISION_TYPES,
  ROUTES_PARTNERS.PROJECT_ISSUE_LIST_TYPES,
];

export const NAVBAR_ITEMS_PARTNERS_PROJECT = [
  {
    name: ROUTES_PARTNERS.PROJECT_DECISION_TYPES,
    flipper: FT.DECISIONS_FULL,
    prefix: 'partnersProjectDecision',
    icon: ['far', 'house-circle-check'],
    title: 'navbar.partner.project.decisions',
    data_test: 'project-navbar-decisions',
  },
  {
    data_test: 'project-navbar-documents',
    icon: ['far', 'file-lines'],
    name: ROUTES_PARTNERS.PROJECT_DOCUMENTS,
    prefix: ROUTES_PARTNERS.PROJECT_DOCUMENTS,
    title: 'navbar.partner.project.documents',
  },
  {
    data_test: 'project-navbar-aftercare',
    icon: ['far', 'house-chimney-heart'],
    name: ROUTES_PARTNERS.PROJECT_ISSUE_LIST_TYPES,
    prefix: 'partnersProjectIssueList',
    title: 'navbar.partner.project.after_care',
    flipper: FT.AFTERCARE,
  },
];

export const NAVBAR_TABLET_ITEMS_PARTNERS_PROJECT = [
  {
    data_test: 'project-navbar-allprojects',
    icon: ['fas', 'arrow-left'],
    name: ROUTES_PARTNERS.CLIENT_PROJECTS,
    title: 'project.labels.projects',
  },
  ...NAVBAR_ITEMS_PARTNERS_PROJECT,
];
