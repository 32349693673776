<template>
  <div>
    <b-autocomplete
      ref="autocomplete"
      v-model="project_name"
      :append-to-body="append_to_body"
      clearable
      :data="filteredProjects"
      data-test="select-project-input"
      field="attributes.name"
      iconRight="circle-xmark"
      open-on-focus
      :placeholder="placeholder"
      @select="selectProject"
      @update:modelValue="searchProject"
    >
      <template #empty>
        <div data-test="empty-result">
          <div v-if="is_searching">
            {{ $t('select.actions.noResults') }} <span v-if="project_name">"{{ project_name }}"</span>
          </div>
          <div v-else>
            {{ $t('select.actions.startTyping') }}
          </div>
        </div>
      </template>
    </b-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'SelectProject',
  props: {
    active: { type: Object, default: null },
    append_to_body: { type: Boolean },
    placeholder: { type: String, default: null },
    projects: { type: Object, default: null },
    max_height: { type: String, default: '200px' },
  },

  emits: ['on-search', 'change-select'],

  data() {
    return {
      project_name: this.active ? this.active.attributes.name : '',
      is_searching: false,
    };
  },

  computed: {
    filteredProjects() {
      return _.filter(this.projects,
        (p) => p.attributes.name.toLowerCase().indexOf(this.project_name.toLowerCase()) >= 0);
    },
  },

  watch: {
    active(newVal) {
      this.project_name = _.get(newVal, 'attributes.name', '');
    },
  },

  methods: {
    searchProject(query) {
      if (query.length >= 1) {
        this.is_searching = true;
        if (!this.active) {
          this.$emit('onSearch', query);
        }
      } else {
        this.is_searching = false;
      }
    },

    selectProject(project) {
      this.$emit('changeSelect', project);
    },
  },

};
</script>
