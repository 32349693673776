<template>
  <div>
    <b-autocomplete
      ref="autocomplete"
      v-model="decision_type_name"
      clearable
      :data="decision_types_filtered"
      data-test="select-decision-type"
      :disabled="is_disabled"
      field="attributes.name"
      iconRight="circle-xmark"
      open-on-focus
      :placeholder="is_disabled ? $t('decision_type.validation.projectRequired') : placeholder"
      @focus="fetchDecisionTypes"
      @select="selectDecisionType">
      <template #empty>
        {{ $t('select.actions.noResults') }}
      </template>
    </b-autocomplete>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SelectDecisionType',
  props: {
    active: { type: Object, default: null },
    project_id: { type: String, default: null },
    unit_id: { type: String, default: null },
    placeholder: { type: String, default: null },
  },

  emits: ['change-select'],

  data() {
    return {
      decision_type_name: this.active ? this.active.attributes.name : '',
    };
  },

  computed: {
    ...mapGetters(['decision_types']),

    decision_types_project() {
      return _.filter(this.decision_types, ['attributes.project_id', this.project_id]);
    },

    decision_types_filtered() {
      return _.filter(this.decision_types_project,
        (p) => p.attributes.name.toLowerCase().indexOf(this.decision_type_name.toLowerCase()) >= 0);
    },
    is_disabled() {
      return _.isNil(this.project_id) && _.isNil(this.unit_id);
    },
  },

  watch: {
    active(newVal) {
      this.decision_type_name = _.get(newVal, 'attributes.name', '');
    },
  },

  methods: {
    ...mapActions(['FETCH_DECISION_TYPES']),

    async fetchDecisionTypes() {
      await this.FETCH_DECISION_TYPES({ project_id: this.project_id });
    },

    selectDecisionType(decision_type) {
      this.$emit('changeSelect', decision_type);
    },
  },

};
</script>
